import React, { useContext } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  AspectRatio,
  useMultiStyleConfig,
  Image as ChakraImage
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import LexicalRichText from '@components/atoms/LexicalRichText';
import { LocaleContext } from '@helpers/LocaleProvider';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import Image from '@components/atoms/Image';
import LazyLoad from '@components/atoms/LazyLoad';
import RowntreeCaveatBackground from '@assets/images/rowntree-caveat-background.webp';
import PiruloCaveatBackground from '@assets/images/pirulo-caveat-background.svg';

// The table columns.
const tableColumns = [`gram`, `gram2`, `portion`, `percent`];

// The table rows.
const tableRows = [
  `energy`,
  `energyKcal`,
  `fat`,
  `saturates`,
  `carbohydrate`,
  `sugars`,
  `fibre`,
  `protein`,
  `salt`
];

const ProductDetail = ({
  slug,
  ingredientsText,
  nutritionalTitle,
  nutritionalCaveatText,
  nutritionalTable,
  nutritionalTableSections,
  image,
  legalText,
  legalCaveatText,
  showLegalSection,
  themeName
}) => {
  const styles = useMultiStyleConfig(`productDetail`);

  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  const { allSiteSettings } = useStaticQuery(graphql`
    {
      allSiteSettings: allStrapiSiteSetting {
        nodes {
          locale
          ingredientsTitle
          nutritionalColumnHeaders {
            gramColumnHeader
            gram2ColumnHeader
            percentColumnHeader
            portionColumnHeader
          }
          nutritionalRowHeaders {
            energyRowHeader
            energKcalRowHeader
            fatRowHeader
            saturatesRowHeader
            carbohydrateRowHeader
            sugarRowHeader
            fibreRowHeader
            proteinRowHeader
            saltRowHeader
          }
        }
      }
    }
  `);
  const siteSettings = allSiteSettings.nodes.find(
    (node) => node.locale === locale
  );

  // Merge old and new nutritonal table fields into one repeatable array.
  let nutritionalTables =
    nutritionalTitle && nutritionalTable
      ? [
          {
            title: nutritionalTitle,
            caveatText: nutritionalCaveatText,
            table: nutritionalTable
          }
        ]
      : [];

  if (
    typeof nutritionalTableSections !== `undefined` &&
    nutritionalTableSections !== null
  ) {
    nutritionalTables = [...nutritionalTables, ...nutritionalTableSections];
  }

  // The table column headers.
  const tableColumnHeaders = tableColumns.map((column) => {
    return siteSettings.nutritionalColumnHeaders[`${column}ColumnHeader`];
  });

  // The table row headers - there are inconsistencies how the fields for these are named.
  // So we need to use different prefixes to extract the field values eg. "energKCal", "sugar"
  const tableRowHeaders = tableRows.map((row) => {
    let prefix = null;

    switch (row) {
      case `energyKcal`:
        prefix = `energKcal`;
        break;
      case `sugars`:
        prefix = `sugar`;
        break;
      default:
        prefix = row;
        break;
    }

    return siteSettings.nutritionalRowHeaders[`${prefix}RowHeader`];
  });

  return (
    <Section __css={styles.container} py={{ base: 10, md: 28 }} mb="0">
      <Wrapper>
        <Grid
          templateColumns="repeat(12, 1fr)"
          columnGap={5}
          rowGap={{ base: 14, md: 28 }}
          alignItems={!showLegalSection ? `center` : null}>
          <GridItem colSpan={{ base: 12, md: 6 }}>
            {image && (
              <LazyLoad>
                <AspectRatio
                  ratio={1}
                  overflow="hidden"
                  display="block"
                  borderRadius="16">
                  <Image image={image} />
                </AspectRatio>
              </LazyLoad>
            )}
          </GridItem>
          {/* If no legal section, have ingredients in own grid item. */}
          {!showLegalSection && showLegalSection !== null && (
            <GridItem colStart={{ md: 8 }} colSpan={{ base: 12, md: 5, xl: 4 }}>
              {/* Ingredients info. */}
              <LazyLoad>
                {siteSettings.ingredientsTitle && (
                  <Heading as="h2" size="sm" textTransform="uppercase" mb="4">
                    {siteSettings.ingredientsTitle}
                  </Heading>
                )}
                {ingredientsText && (
                  <Box>
                    <LexicalRichText
                      data={{ richText: { ...ingredientsText.richText } }}
                      paddingSides="0"
                      maxWidth="none"
                    />
                  </Box>
                )}
              </LazyLoad>
            </GridItem>
          )}
          {/* If legal section is required, set legal info and ingredients
              in their own separate grid items. */}
          {showLegalSection && showLegalSection !== null && (
            <>
              {/* Legal info */}
              <GridItem
                colSpan={{ base: 12, md: 6 }}
                display="flex"
                flexDirection="column"
                alignItems={{ md: `center` }}
                alignSelf={{ md: `center` }}>
                <Box
                  position="relative"
                  width="100%"
                  maxW="37.5613rem"
                  display="flex"
                  flexDirection="column">
                  <ChakraImage
                    src={
                      themeName === `rowntrees`
                        ? RowntreeCaveatBackground
                        : PiruloCaveatBackground
                    }
                    alt=""
                    width="100%"
                  />
                  {typeof legalText !== `undefined` && legalText !== null && (
                    <Text
                      position="absolute"
                      top="46%"
                      fontSize={{ base: `lg`, md: `xl` }}
                      fontWeight="600"
                      width="auto"
                      maxWidth="91%"
                      ml="38%"
                      mr="8%"
                      mb="0"
                      transform="translateY(-50%)"
                      zIndex="1">
                      {legalText}
                    </Text>
                  )}
                </Box>
                {typeof legalCaveatText?.richText !== `undefined` &&
                  legalCaveatText?.richText !== null && (
                    <Box
                      mt={{ base: 6, md: 12 }}
                      sx={{ '*:last-child': { paddingBottom: 0 } }}>
                      <LexicalRichText
                        data={{ richText: { ...legalCaveatText.richText } }}
                        paddingSides="0"
                        maxWidth="none"
                      />
                    </Box>
                  )}
              </GridItem>
              {/* Ingredients info */}
              <GridItem colStart={{ xl: 2 }} colSpan={{ base: 12, md: 6 }}>
                <LazyLoad>
                  {siteSettings.ingredientsTitle && (
                    <Heading as="h2" size="sm" textTransform="uppercase" mb="4">
                      {siteSettings.ingredientsTitle}
                    </Heading>
                  )}
                  {ingredientsText && (
                    <Box>
                      <LexicalRichText
                        data={{ richText: { ...ingredientsText.richText } }}
                        paddingSides="0"
                        maxWidth="none"
                      />
                    </Box>
                  )}
                </LazyLoad>
              </GridItem>
            </>
          )}
        </Grid>
      </Wrapper>
      <Wrapper px={{ base: 0, md: 14 }}>
        <Grid
          templateColumns={{ md: `repeat(12, 1fr)` }}
          columnGap={5}
          mt={{ base: 14, md: 28 }}>
          <GridItem
            colSpan={{ md: 12, xl: 10 }}
            colStart={{ xl: 2 }}
            overflow="hidden">
            <VStack gap="14" align="left">
              {nutritionalTables.map(({ title, caveatText, table }) => {
                // Combine table data from fields.
                let tableData = tableRows.reduce(
                  (a, b) => [
                    ...a,
                    tableColumns.map((column) => {
                      if (typeof table === `undefined` || table === null) {
                        return `-`;
                      }

                      const fieldName = `${b}${
                        column.charAt(0).toUpperCase() + column.slice(1)
                      }`;

                      if (
                        typeof fieldName === `undefined` ||
                        fieldName === null
                      ) {
                        return `-`;
                      }

                      const fieldEntry = table[fieldName];

                      return typeof fieldEntry !== `undefined` &&
                        fieldEntry !== null
                        ? fieldEntry
                        : `-`;
                    })
                  ],
                  []
                );

                // Filter columns to display if they have values.
                const columns = tableColumns
                  .filter((_, index) => {
                    return tableData.every((data) => data[index]);
                  })
                  .map((column, index) => {
                    return {
                      columnData: column,
                      columnIndex: index
                    };
                  });

                // Re-combine table data based on the filtered columns.
                tableData = tableData.map((data, rowIndex) => {
                  return {
                    rowIndex,
                    columns: columns.map(({ columnData }, columnIndex) => ({
                      columnData: data[tableColumns.indexOf(columnData)],
                      columnIndex
                    }))
                  };
                });

                return (
                  <LazyLoad key={`${slug}-nutrition-table`}>
                    {typeof title !== `undefined` && (
                      <Heading
                        as="h2"
                        size="md"
                        textTransform="uppercase"
                        px={{ base: 6, md: 0 }}
                        mb={{ base: 4, md: 8 }}>
                        {title}
                      </Heading>
                    )}
                    <TableContainer>
                      <Table
                        variant="simple"
                        sx={{
                          tableLayout: { md: `fixed` }
                        }}
                        whiteSpace="normal">
                        <Thead>
                          <Tr>
                            <Th />
                            {columns.map((column) => (
                              <Th
                                key={`${slug}-table-heading-${column.columnIndex}`}>
                                {
                                  tableColumnHeaders[
                                    tableColumns.indexOf(column.columnData)
                                  ]
                                }
                              </Th>
                            ))}
                          </Tr>
                        </Thead>
                        {typeof table !== `undefined` && (
                          <Tbody>
                            {tableData.map((data, index) => {
                              const key = `${slug}-table-row-${data.rowIndex}`;
                              return (
                                <Tr key={key}>
                                  <Td>{tableRowHeaders[index]}</Td>
                                  {data.columns.map((value) => (
                                    <Td key={`${key}-${value.columnIndex}`}>
                                      {value.columnData}
                                    </Td>
                                  ))}
                                </Tr>
                              );
                            })}
                          </Tbody>
                        )}
                      </Table>
                    </TableContainer>
                    {typeof caveatText !== `undefined` && (
                      <Text fontSize="sm" mt="6" mb="0" px={{ base: 6, md: 0 }}>
                        {caveatText}
                      </Text>
                    )}
                  </LazyLoad>
                );
              })}
            </VStack>
          </GridItem>
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default ProductDetail;
